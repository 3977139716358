.o-sticky {
	@include space(top);
	position: sticky;
	z-index: layer(modal);
	
	&--top {
		top: 0;
	}
	&--bottom {
		bottom: 0;
	}
}