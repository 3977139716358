.table {
    width: 100%;

    thead {
        background-color: var(--color-secondary--normal);
        color: white;
    }
    tbody tr {
        background-color: white;
    }
    // tr:nth-child(even) {
    //     background-color: map-get($base__colors, "lily-white");
    // }
    td,
    th {
        padding: .5em .75em;
    }
    th {
        text-align: left;
    }

    @include media('m-to') {
        thead {
            display: none;
        }
        &,
        tr,
        td,
        tbody {
            display: block;
        }
        tr:not(.\.) {
            background-color: transparent;
        }
        tr + tr {
            border-top: 2px solid map-get($base__colors, "lily-white");
            padding-top: .75em;
        }
        td {
            padding-top: 0;
            padding-left: 0;
            padding-right: 0;
        }
        [data-th]::before {
            content: attr(data-th);
            display: inline-block;
            font-weight: bold;
            margin-right: .75em;
            min-width: 100px;
            white-space: nowrap;
        }
    }
}