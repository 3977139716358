.featured {
    position: relative;

    &::before {
        @include typo--body(s);
        color: map-get($base__colors, "white");
        background-color: var(--color-primary--normal);
        content: "featured";
        padding: .25em .5em;
        position: absolute;
            right: 0;
            top: 0;
        z-index: layer(above);
    }
}
