@function pow($x, $n) {
    $ret: 1;

    @if $n >= 0 {
        @for $i from 1 through $n {
            $ret: $ret * $x;
        }
    } @else {
        @for $i from $n to 0 {
            $ret: $ret / $x;
        }
    }

    @return $ret;
}

@mixin typo--head($size: 0, $important: false) {

    $imp: if($important, !important, null);
    $base: $base__font-size;

    color: $base__color--head $imp;
    
    @each $_key, $_val in $templates {
        [data-theme="#{$_key}"] & {
            font-weight: templates($_key, "headings-weight");
        }
    }

    @each $_name, $_s in $base__heading-sizes {
        @if $size == $_name {
            font-family: var(--font-h#{$size}) $imp;
            font-size: heading-sizes($size, "font-size") $imp;
            line-height: heading-sizes($size, "line-height") $imp;
            @if heading-sizes($size, "letter-spacing") {
                letter-spacing: heading-sizes($size, "letter-spacing");
            }
        }
    }

    @include media(l-up) {
        @each $_name, $_s in $desktop__heading-sizes {
            @if $size == $_name {
                font-size: desktop-heading-sizes($size, "font-size") $imp;
                line-height: desktop-heading-sizes($size, "line-height") $imp;
                @if desktop-heading-sizes($size, "letter-spacing") {
                    letter-spacing: desktop-heading-sizes($size, "letter-spacing");
                }
            }
        }
    }
}
@mixin typo--body($size: m, $important: false) {

    $imp: if($important, !important, null);

    //color: $base__color $imp;
    //font-family: $base__font-family $imp;
    // font-weight: map-get($base__font-weights, "thin") $imp;

    @if $size == s {
        font-size: $base__font-size--small $imp;
        line-height: $base__line-height--small $imp;
    }
    @if $size == m {
        font-size: $base__font-size $imp;
        line-height: $base__line-height $imp;
    }
    @if $size == l {
        font-size: $base__font-size--large $imp;
        line-height: $base__line-height--large $imp;
    }
}
