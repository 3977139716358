.address {

    &__map {
        background-color: map-get($base__colors, "white");
        padding: 5px;
    }
    &__details {
        font-style: normal;
        position: relative;

        :link,
        :visited {
            padding-bottom: 0;
            position: absolute;
            left: 80px;
        }
    }
}
