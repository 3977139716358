.u-space {
    //@include media-generate(".u-space") {
        @include space($important: true);
    //}

    //@include media-generate(".u-space--t") {
    &--t {
        @include space--t($important: true);
    }
    //@include media-generate(".u-space--s") {
    &--s {
        @include space--s($important: true);
    }
    //@include media-generate(".u-space--m") {
    &--m {
        @include space--m($important: true);
    }
    //@include media-generate(".u-space--l") {
    &--l {
        @include space--l($important: true);

        &.u-space--bump {
            @include media(h-x) {
                @include space--l($addend: space--l(), $important: true);
            }
        }
    }
    //@include media-generate(".u-space--x") {
    &--x {
        @include space--x($important: true);

        &.u-space--bump {
            @include media(h-x) {
                @include space--x($addend: space--l(), $important: true);
            }
        }
    }
    //@include media-generate(".u-space--h") {
    &--h {
        @include space--h($important: true);

        &.u-space--bump {
            @include media(l-up) {
                @include space--h($addend: space--l(), $important: true);
            }
        }
    }

    //@include media-generate(".u-space--t-") {
    &--t- {
        @include space--t($multiplier: -1, $important: true);
    }
    //@include media-generate(".u-space--s-") {
    &--s- {
        @include space--s($multiplier: -1, $important: true);
    }
    //@include media-generate(".u-space--m-") {
    &--m- {
        @include space--m($multiplier: -1, $important: true);
    }
    //@include media-generate(".u-space--l-") {
    &--l- {
        @include space--l($multiplier: -1, $important: true);
    }
    //@include media-generate(".u-space--x-") {
    &--x- {
        @include space--x($multiplier: -1, $important: true);

        &.u-space--bump {
            @include media(h-x) {
                @include space--x($multiplier: -1, $addend: space--l(), $important: true);
            }
        }
    }
    //@include media-generate(".u-space--h-") {
    &--h- {
        @include space--h($multiplier: -1, $important: true);

        &.u-space--bump {
            @include media(h-x) {
                @include space--h($multiplier: -1, $addend: space--l(), $important: true);
            }
        }
    }
}

@include media-generate(".u-space--n") {
// &--n {
    @include space--n($important: true);
}
@include media-generate(".u-space--m") {
// &--m {
    @include space--m($important: true);
}
