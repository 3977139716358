@viewport {
    zoom: 1.0;
    width: extend-to-zoom;
}

//**
//* Global variables
//*/

// font and spacing

$base__font-size: 16px !default;
$base__font-size--small: 14px !default;
$base__font-size--large: 20px !default;
$base__line-height: 24px !default;
$base__line-height--small: 20px !default;
$base__line-height--large: 28px !default;


$base__heading-sizes: (
    1: (
        "font-size": 30px,
        "line-height": 38px / 30px,
        "letter-spacing": -1px
    ),
    2: (
        "font-size": 23px,
        "line-height": 31px / 23px
    ),
    3: (
        "font-size": 19px,
        "line-height": 28px / 19px
    ),
    4: (
        "font-size": 16px,
        "line-height": 24px / 16px
    ),
    5: (
        "font-size": 14px,
        "line-height": 20px / 14px
    ),
    6: (
        "font-size": 12px,
        "line-height": 18px / 12px
    ),
) !default;

$desktop__heading-sizes: (
    1: (
        "font-size": 52px,
        "line-height": 60px / 52px,
        "letter-spacing": -1px
    ),
    2: (
        "font-size": 32px,
        "line-height": 40px / 32px
    ),
    3: (
        "font-size": 19px,
        "line-height": 28px / 19px
    ),
    4: (
        "font-size": 16px,
        "line-height": 24px / 16px
    ),
    5: (
        "font-size": 14px,
        "line-height": 20px / 14px
    ),
    6: (
        "font-size": 12px,
        "line-height": 18px / 12px
    ),
) !default;

/*
 * Example:
 * font-size: heading-sizes(1, "font-size");
 */
@function heading-sizes($size, $property) {
    @return map-get(map-get($base__heading-sizes, $size), $property);
}

/*
 * Example:
 * font-size: heading-sizes(1, "font-size");
 */
@function desktop-heading-sizes($size, $property) {
    @return map-get(map-get($desktop__heading-sizes, $size), $property);
}

$base__space-unit: 20px !default;

// colours
$base__colors: (
    "white": #FFFFFF,
    "lily-white": #EEEEEE,
    "iron": #CCCCCC,
    "light-gray": #DDDDDD,
    "dark-gray": #555555,
    "mine-shaft": #333333,
    "black": #000000,

    "fall": #EE2964,
    "foliage": #BD2737,
    "summer": #FAA61A,
    "sunshine": #F58220,
    "winter": #00BBE7,
    "ice": #0091B8,
    "spring": #9ECC3B,
    "grass": #41AD49,
) !default;

$base__colors--overlay: (
    "white": rgba(black, .1),
    "iron": rgba(black, .2),
    "magnesium": rgba(black, .3),
    "steel": rgba(black, .6),
    "mine-shaft": rgba(black, .8),
) !default;

$base__colors--veillay: (
    "black": rgba(white, .1),
    "mine-shaft": rgba(white, .2),
    "steel": rgba(white, .4),
    "oslo": rgba(white, .7),
    "iron": rgba(white, .8),
    "white": rgba(white, .9),
) !default;

$base__color: #333333 !default;
$base__color--head: $base__color !default;
$base__color--link: $base__color !default;
$base__color--focus: map-get($base__colors, fall) !default;
$base__background-color: #FFFFFF !default;

// breakpoints

// webflow.com breakpoints
// $base__breakpoint: (
//     s: 479px,
//     m: 767px,
//     l: 1400px,
//     x: null,
// ) !default;

$base__breakpoint: (
    s: 500px,
    m: 850px,
    l: 1300px,
    x: null,
) !default;

// NOTE: a breakpoint with true as a second value means the style will be included but not wrapped in any media query

$base__breakpoints: (
    s:    "(max-width: " + (map-get($base__breakpoint, s)) + ")",
    m-up: "(min-width: " + (map-get($base__breakpoint, s) + 1) + ")",
    m-to: "(max-width: " + (map-get($base__breakpoint, m)) + ")",
    m:    "(min-width: " + (map-get($base__breakpoint, s) + 1) + ") and (max-width: " + (map-get($base__breakpoint, m)) + ")",
    l-up: "(min-width: " + (map-get($base__breakpoint, m) + 1) + ")",
    l-to: "(max-width: " + (map-get($base__breakpoint, l)) + ")",
    l:    "(min-width: " + (map-get($base__breakpoint, m) + 1) + ") and (max-width: " + (map-get($base__breakpoint, l)) + ")",
    x:    "(min-width: " + (map-get($base__breakpoint, l) + 1) + ")",

    h-m:  "(min-height: " + (map-get($base__breakpoint, s) + 1) + ")",
    h-l:  "(min-height: " + (map-get($base__breakpoint, m) + 1) + ")",
    h-x:  "(min-height: " + (map-get($base__breakpoint, l) + 1) + ")"
) !default;
