body {
    @include typo--body(m);
    text-rendering: optimizeLegibility;

    color: $base__color;
    font-family: var(--font-body), sans-serif;
}

@each $_name, $_val in $templates {
    body[data-theme="#{$_name}"] {
        background-color: templates($_name, "background-color");
        color: templates($_name, "body-color");
    }
}
