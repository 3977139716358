// Template overwrites
@each $_name, $_val in $templates {
    [data-theme="#{$_name}"] .footer {
        background-color: templates($_name, "footer-bg-color");

        :link,
        :visited {
            color: map-get($base__colors, "white");
            border-bottom-color: rgba(map-get($base__colors, "white"), .2);
            font-weight: bold;

            &:hover,
            &:focus {
                border-bottom-color: map-get($base__colors, "white");
                color: map-get($base__colors, "white");
            }
        }
    }
}
