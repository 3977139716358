.palette {
    border-bottom: space--s() solid map-get($base__colors--overlay, "magnesium");
    height: space--x(2);
}
@each $_name in $template__color-list {
    .palette--c-#{$_name} {
        background-color: var(--color-#{$_name}--normal);
        border-color: var(--color-#{$_name}--focus);
    }
}
