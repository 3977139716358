.navigation {
    @include media(m-to) {
        background-color: white;
        position: absolute;
        margin-top: space();
        z-index: 1;
        width: 100vw;
        
    }
    @include media(s) {
        right: -$o-container--padding-s;
    }
    @include media(m) {
        right: -$o-container--padding-l;
    }
    @include media(l-up) {
        &.is-hidden:not(.\.) {
            display: block;
        }
    }
}

@each $_name, $_val in $templates {
    [data-theme="#{$_name}"] .navigation {
        @include media(m-to) {
            background-color: templates($_name, "nav-bg");

            &.is-transitioned.is-hidden {
                display: none;
            }
        }

        @include media(l-up) {
            &.curtain:not(.is-transitioned),
            &.curtain.is-transitioned {
                height: auto;
                overflow: visible;
            }
        }
    }
}
