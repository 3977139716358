//**
//* Will contain its content entirely (i.e. top/bottom margins won"t merge).
//* You can optionally pass left/right/both to make the container act as clearfix.
//*/
@mixin box($clear: null) {
    &:after,
    &:before {
        content: "";
        display: table;
    }
    @if $clear != null {
        &:after {
            clear: $clear;
        }
    }
}