:link,
:visited {
    transition: {
        duration: 250ms;
        property: color, border-bottom-color;
    }

    text-decoration: none;
    color: map-get($base__colors, "dark-gray");
    border-bottom: 2px solid rgba(map-get($base__colors, "dark-gray"), .8);
    padding-bottom: 1px;

    &:hover,
    &:focus {
        border-bottom-color: map-get($base__colors, "dark-gray");
        color: map-get($base__colors, "black");
    }

    &.arrow-link {
        &:after {
            content: "➝";
            margin-left: 5px;
        }
    }
}

// Template overwrites
@each $_name, $_val in $templates {
    [data-theme="#{$_name}"] :link,
    [data-theme="#{$_name}"] :visited {
        border-bottom-color: currentColor;
        color: templates($_name, "links-color");
        font-weight: templates($_name, "links-weight");

        &:hover,
        &:focus {
            color: templates($_name, "links-color--focus");
        }
    }
}

.b-link--call {
    border: 2px solid var(--color-secondary--normal);
    color: var(--color-secondary--normal);
    display: inline-block;
    padding: .25em .5em;
    transition-property: color, border-color;

    &:hover,
    &:focus {
        border-color: var(--color-secondary--focus);
        color: var(--color-secondary--focus);
    }
}
.b-link--reset,
.b-links--reset :link,
.b-link--icon,
.b-links--icon :link {
    border-bottom: none !important;
    font-weight: inherit !important;
    padding-bottom: 0 !important;

    &:not(:hover):not(:focus) {
        color: inherit !important;
    }
}
.b-link--title,
.b-links--title :link {
    font-weight: inherit;
    padding-bottom: 0;

    @if $base__color--link != $base__color--head {
        &:not(:hover):not(:focus) {
            color: inherit;
        }
    }
}
.b-link--negative,
.b-links--negative :link {
    &:not(#_) {
        border-bottom-color: rgba(map-get($base__colors, "white"), .3);

        &,
        &:hover,
        &:focus {
            color: map-get($base__colors, "white");
        }
        &:hover,
        &:focus {
            border-bottom-color: map-get($base__colors, "white");
        }
    }
}
.b-link--icon,
.b-links--icon :link {
    &:not(#_) {
        transition: 250ms opacity;

        &:hover,
        &:focus {
            opacity: .7;
        }
    }
}
.b-link--important {
    &:not(#_) {
        color: map-get($base__colors, "fall");
    }
}
