.o-fullscreen {
    max-width: none;
    position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 9999;
    display: flex;
        flex-direction: row;
        align-items: center;

    & > * {
        flex: auto;
    }
}
.o-fullscreen:not(:target) {
    display: none;
}

.o-fullscreen__close {
    position: absolute;
        top: space();
        right: space();
}
