.breadcrumb {
    &__list {
        @include reset(ol);
        @include grid();
    }
    &__icon--arrow {
        transform: rotate(90deg);
        width: 12px;
    }
}
