.popup-contact-us {
    position: fixed;
    bottom: 0;
    right: 0;
    max-width: 100%;

    &.is-builder .popup-contact-us__overlay,
    &:not(.is-builder) .popup-contact-us__hide,
    &:not(.is-shopper) .popup-contact-us__notice {
        display: none;
    }
}
.popup-contact-us__trigger {
    float: right;
    display: flex;
    align-items: center;
    gap: 1em;
    
    & span + svg {
        display: none;
    }

    .popup-contact-us[open] & {
        padding: 1em;
        border-radius: 100%;
        border: 1px solid white;
        transform: translate(25%, 75%);

        & svg,
        & span {
            display: none;
        }
        & span + svg {
            display: block;
        }
    }
}
.popup-contact-us__body {
    clear: right;
    min-width: 360px;
    max-width: 360px;
    max-height: 80vh;
    overflow: auto;
    box-shadow: 0 0 5px 0 rgba(black,0.5);
}
.popup-contact-us__choice {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
