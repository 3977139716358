.box {
    @include box();
    @include space(padding-left padding-right padding-top);

    transition-property: color, background-color;

    &,
    hr,
    h1, .h1,
    h2, .h2,
    h3, .h3,
    h4, .h4,
    h5, .h5,
    h6, .h6,
    :link, :visited {
        transition-duration: 250ms;
    }

    hr,
    h1, .h1,
    h2, .h2,
    h3, .h3,
    h4, .h4,
    h5, .h5,
    h6, .h6,
    :link, :visited {
        transition-property: color;
    }
    hr {
        transition-property: border-bottom-color;
    }

    @include media(m x) {
        @include space--s(padding-bottom);
        @include space--l(padding-left padding-right padding-top);
    }
}

@include media-generate(".box--c") {
    border: 3px solid map-get($base__colors, "light-gray");
}

@each $_name in $template__color-list {
    .box--#{$_name} {
        border-color: var(--color-#{$_name}--normal);

        &.is-active {
            background-color: var(--color-#{$_name}--normal);

            &,
            h1, .h1,
            h2, .h2,
            h3, .h3,
            h4, .h4,
            h5, .h5,
            h6, .h6,
            :link, :visited {
                color: map-get($base__colors, "white");
            }
            hr {
                border-bottom-color: map-get($base__colors, "white");
            }
        }
    }
    .box--#{$_name}-transparent {
        background-color: var(--color-#{$_name}--transparent);
    }
}

@each $_name, $_val in $templates {
    [data-theme="#{$_name}"] .box {
        padding: templates($_name, "padding");
    }
}
