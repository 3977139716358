.veillay {
    @include box();
}
@each $_name, $_color in $base__colors {
    .veillay--c-#{$_name} {
        background-color: rgba($_color, .1);
    }
}
@each $_name, $_color in $base__colors--veillay {
    .veillay--c-#{$_name} {
        background-color: $_color;
    }
}
@each $_name in $template__color-list {
    .veillay--c-#{$_name} {
        background-color: var(--color-#{$_name}--normal);
        color: var(--color-#{$_name}--contrast);

        &.is-active {
            &:hover,
            &:focus {
                background-color: var(--color-#{$_name}--focus);
            }
        }

        &:checked {
            background-color: var(--color-#{$_name}--focus);
        }
    }
}
