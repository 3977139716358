.coverlay {
    @include box();
}

@each $_name, $_color in $base__colors {
    .coverlay--c-#{$_name} {
        background-color: $_color;
    }
}

@each $_name in $template__color-list {
    .coverlay--c-#{$_name} {
        background-color: var(--color-#{$_name}--normal);
    }
}

@each $_name in $template__color-list {
    .coverlay--c-#{$_name}--focus {
        background-color: var(--color-#{$_name}--focus);
    }
}
