//**
//* This is a fully responsive grid
//*
//* - fluid
//* - nestable
//* - semantic
//* - responsive
//*      - default s (small), m (medium), l (large) and x (extralarge) grids
//*      - default 1 to 12 columns grids
//* - optional gutter (vertical space between elements, in sizes s, m, l and x, or custom)
//* - optional spacer (horizontal space between elements, in sizes s, m, l and x, or custom)
//* - optional inline (the grid is an inline element)
//* - optional nowrap (the items won"t wrap)
//* - optional rowmid (centers the items horizontally)
//* - optional rowend (aligns the items to the end of each row)
//* - optional colmid (centers the items vertically)
//* - optional colend (aligns the items to the end of each column)
//*/



//* ========================================================================== */
//* GLOBAL OPTIONS =========================================================== */
//* ========================================================================== */

$grid__expose: false !default;                       // whether to generate the grid explicitly
$grid__expose--options: null !default;               // the options to generate when $grid__expose is true
$grid__expose--default-options: () !default;         // the default options to generate when $grid__expose is true
$grid__expose__item--options: null !default;         // the options for the items to generate when $grid__expose is true
$grid__expose__item--default-options: () !default;   // the options for the items to generate when $grid__expose is true
$grid__ns: unquote(".grid") !default;                // the prefix to use when $grid__expose is true
$grid__ns__item: unquote("__item") !default;         // the prefix for the items to use when $grid__expose is true
$grid__breakpoints: $base__breakpoints !default;     // the breakpoints to use when $grid__expose is true
$grid__items: 1 2 3 4 5 6 7 8 9 10 11 12 !default;   // the number of grids to generate when $grid__expose is true
$grid__no-media: true !default;                      // whether to define the grid without media modifier when $grid__expose is true
$grid__space-unit: $base__space-unit !default;       // the space for gutters and spacers (and possibly something else in the future)
$grid__notify-js: false !default;                    // notifies the js when switching between breakpoints
$grid__no-flexbox-fallback: true !default;           // supports older browsers which don"t support flexbox

//* ========================================================================== */
//* PUBLIC METHODS =========================================================== */
//* ========================================================================== */

//**
//* Defines a grid container.
//*
//* @param $options (optional) some options for the grid (for example, gutter)
//*/
@mixin grid($options: ()) {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    align-content: flex-start;

    @include _grid__no-flexbox {
        display: block;
        letter-spacing: -0.31em;

        > * {
            display: inline-block;
            letter-spacing: normal;
            vertical-align: top;
        }
    }

    @include grid--options($options);
}
//**
//* Defines a grid container"s options.
//*
//* @param $options some options for the grid (for example, gutter)
//*/
@mixin grid--options($options: ()) {
    @each $option in $options {
        $gutter: $grid__space-unit;
        $spacer: $grid__space-unit;

        // gutter options
        @if length($option) == 2 and nth($option, 1) == gutter {
            $gutter: nth($option, 2);
            $option: nth($option, 1);
        }
        @if index(gutter--t gutter--s gutter--m gutter gutter--l gutter--x gutter--h gutter--g, $option) {
            @if $option == gutter--t {
                $gutter: 1/4 * $gutter;
            }
            @if $option == gutter--s {
                $gutter: 1/2 * $gutter;
            }
            @if $option == gutter--l {
                $gutter: 1.5 * $gutter;
            }
            @if $option == gutter--x {
                $gutter: 2.0 * $gutter;
            }
            @if $option == gutter--h {
                $gutter: 2.5 * $gutter;
            }
            @if $option == gutter--g {
                $gutter: 5 * $gutter;
            }

            margin-left: -$gutter;
            > * {
                padding-left: $gutter;
            }
        }
        // spacer options
        @if length($option) == 2 and nth($option, 1) == spacer {
            $spacer: nth($option, 2);
            $option: nth($option, 1);
        }
        @if index(spacer--t spacer--s spacer--m spacer spacer--l spacer--x spacer--h space--g, $option) {
            @if $option == spacer--t {
                $spacer: 1/4 * $spacer;
            }
            @if $option == spacer--s {
                $spacer: 0.5 * $spacer;
            }
            @if $option == spacer--l {
                $spacer: 1.5 * $spacer;
            }
            @if $option == spacer--x {
                $spacer: 2.0 * $spacer;
            }
            @if $option == spacer--h {
                $spacer: 2.5 * $spacer;
            }
            @if $option == spacer--g {
                $spacer: 3.5 * $spacer;
            }

            margin-top: -$spacer;
            > * {
                padding-top: $spacer;
            }
        }
        @if $option == stretch {
            align-content: stretch;
        }
        @if $option == column {
            flex-direction: column;
        }
        @if $option == inline {
            display: inline-flex;
            @include _grid__no-flexbox {
                display: inline-block;
            }
        }
        @if $option == nowrap {
            flex-wrap: nowrap;
            @include _grid__no-flexbox {
                white-space: nowrap;
            }
        }
        @if $option == rowmid {
            justify-content: center;
            @include _grid__no-flexbox {
                text-align: center;
            }
        }
        @if $option == rowend {
            justify-content: flex-end;
            @include _grid__no-flexbox {
                text-align: right;
            }
        }
        @if $option == colmid {
            align-content: center;
            align-items: center;
            @include _grid__no-flexbox {
                > * {
                    vertical-align: middle;
                }
            }
        }
        @if $option == colend {
            align-content: flex-end;
            align-items: flex-end;
            @include _grid__no-flexbox {
                > * {
                    vertical-align: bottom;
                }
            }
        }
    }
}
//**
//* Defines a grid item.
//*
//* @param $column the column definition in the form A B,
//*                where A is the width of the column and B is the number of columns
//*                (if in a different form, it is treated as $options)
//*      e.g. 1 3 for a 1/3 (one third) column
//* @param $options optional options for the column
//*      e.g. skip 1 5, pull 2 3
//*/
@mixin grid__item($column, $options: ()) {
    //* if the first parameter is in the form <number> <number> e.g. 1 3 ... */
    @if length($column) == 2
        and
        type_of(nth($column, 1)) == number
        and
        unitless(nth($column, 1))
        and
        type_of(nth($column, 2)) == number
        and
        unitless(nth($column, 2))
    {
        width: percentage(nth($column, 1) / nth($column, 2));
    }
    //* else it is assumed the first parameter is actually a list of options */
    @else {
        $options: $column;
    }

    @include grid__item--options($options);
}
//**
//* Defines a grid item"s options.
//*
//* @param $options e.g. skip 1 5, pull 2 3
//*/
@mixin grid__item--options($options: ()) {
    @each $option in $options {
        @if length($option) == 3 {

            $span: percentage(nth($option, 2) / nth($option, 3));

            @if index(push pull, nth($option, 1)) {
                position: relative;
            }
            @if nth($option, 1) == push {
                left: $span;
            }
            @if nth($option, 1) == pull {
                right: $span;
            }
            @if nth($option, 1) == skip {
                margin-left: $span;
            }
        }
        @else {

            @if index(rowmid rowend, $option) {
                @include _grid__no-flexbox() {
                    display: block;
                }
            }
            @if $option == rowmid {
                margin: { left: auto; right: auto; }
            }
            @if $option == rowend {
                margin-left: auto;
            }
            @if $option == colmid {
                align-self: center;
                @include _grid__no-flexbox() {
                    vertical-align: middle;
                }
            }
            @if $option == colend {
                align-self: flex-end;
                @include _grid__no-flexbox() {
                    vertical-align: bottom;
                }
            }
            @if index(floatl floatr, $option) {
                position: absolute;
            }
            @if $option == floatl {
                left: 0;
            }
            @if $option == floatr {
                right: 0;
            }
            @if $option == fill {
                flex: 1 0 auto;
            }
            @if $option == head {
                order: -1;
            }
            @if $option == tail {
                order: 999999;
            }
            @if $option == auto {
                flex: 1;
            }
        }
    }
}



//* ========================================================================== */
//* PRIVATE METHODS ========================================================== */
//* ========================================================================== */

$_grid-options: if($grid__expose--options, $grid__expose--options, (gutter, gutter--t, gutter--s, gutter--m, gutter--l, gutter--x, gutter--h, gutter--g, spacer, spacer--t, spacer--s, spacer--m, spacer--l, spacer--x, spacer--h, spacer--g, stretch, column, inline, nowrap, rowmid, rowend, colmid, colend));
$_grid-item-options: if($grid__expose__item--options, $grid__expose__item--options, (push, pull, skip, auto, fill, head, tail, rowmid, rowend, colmid, colend, floatl, floatr));

// wraps style for browsers which don"t (fully) support flexbox
@mixin _grid__no-flexbox() {
    @if $grid__no-flexbox-fallback {

        // generates the code for a selector in the form "html.has-no-flexbox <the-current-selector>"
        @at-root {
            html.has-no-flexbox & {
                @content;
            }
        }

        // if the browser supports @supports
        @supports ( not ((-moz-flex-wrap: wrap) or (flex-wrap: wrap)) ) {
            @content;
        }

        // code for Apple Safari 5
        @media screen and (-webkit-min-device-pixel-ratio: 0) {
            @at-root {
                ::i-block-chrome, & {
                    @content;
                }
            }
        }
    }
}

// generates the grid
@mixin _grid__setup($grid-breakpoint-size: null) {
    $media-suffix: if($grid-breakpoint-size, #{nth($media__selector-suffix, 1)}#{$grid-breakpoint-size}#{nth($media__selector-suffix, 2)}, null);

    #{$grid__ns}#{$media-suffix} {
        @include grid($grid__expose--default-options);
    }
    // generates the grid options for each grid option
    @each $grid-option in $_grid-options {
        #{$grid__ns}--#{$grid-option}#{$media-suffix} {
            @include grid--options($grid-option);
        }
    }
}
@mixin _grid__items-setup($grid-breakpoint-size: null) {
    $media-suffix: if($grid-breakpoint-size, #{nth($media__selector-suffix, 1)}#{$grid-breakpoint-size}#{nth($media__selector-suffix, 2)}, null);

    @include grid__item--options($grid__expose__item--default-options);

    // generates the grid items for each grid size
    @each $i in $grid__items {
        // generates the grid items for this grid size
        $j: 1;
        @while $j <= $i {

            $span: percentage($j/$i);

            #{$grid__ns + $grid__ns__item}--#{$j}-#{$i}#{$media-suffix} {
                @include grid__item($j $i);
            }

            @if index($_grid-item-options, push) {
                #{$grid__ns + $grid__ns__item}--push--#{$j}-#{$i}#{$media-suffix} {
                    @include grid__item--options((push $j $i));
                }
            }
            @if index($_grid-item-options, pull) {
                #{$grid__ns + $grid__ns__item}--pull--#{$j}-#{$i}#{$media-suffix} {
                    @include grid__item--options((pull $j $i));
                }
            }
            @if index($_grid-item-options, skip) {
                #{$grid__ns + $grid__ns__item}--skip--#{$j}-#{$i}#{$media-suffix} {
                    @include grid__item--options((skip $j $i));
                }
            }

            $j: $j + 1;
        }
    }

    // generates the grid item options for each grid item option
    @each $grid-item-option in $_grid-item-options {
        @if not index(push pull skip, $grid-item-option) {
            #{$grid__ns + $grid__ns__item}--#{$grid-item-option}#{$media-suffix} {
                @include grid__item--options($grid-item-option);
            }
        }
    }
}


//* ========================================================================== */
//* ========================================================================== */
//* ========================================================================== */

//**
//* This will notify JavaScript for changes on the grid scripts when resizing the browser"s window
//*
//* See http://css-tricks.com/media-query-change-detection-in-javascript-through-css-animations/
//*/
@if $grid__notify-js {
    #{$grid__ns}--notify-js {
        animation-duration: 1ms;
    }
}

// define the grids outside breakpoints
@if $grid__no-media and $grid__expose {
    @include _grid__setup();
    @include _grid__items-setup();
}

@mixin _grid__media($grid-breakpoint-size, $grid-breakpoint-anim) {
    @if $grid__expose {
        @include _grid__setup($grid-breakpoint-size);
        @include _grid__items-setup($grid-breakpoint-size);
    }
    @if $grid__notify-js {
        #{$grid__ns}--notify-js {
            animation-name: $grid-breakpoint-anim;
        }
    }
}

// define the grids for each breakpoint
@each $grid-breakpoint-size, $grid-breakpoint-rule in $grid__breakpoints {

    $grid-breakpoint-anim: $grid__ns + "__m(" + $grid-breakpoint-size + ")";

    @if $grid__notify-js {
        @keyframes #{$grid-breakpoint-anim} {
            from { clip: rect(1px, auto, auto, auto); }
            to   { clip: rect(0px, auto, auto, auto); }
        }
    }

    @if type-of($grid-breakpoint-rule) == string {
        @media #{$grid-breakpoint-rule} {
            @include _grid__media($grid-breakpoint-size, $grid-breakpoint-anim);
        }
    }
    @else if $grid-breakpoint-rule == true {
        @include _grid__media($grid-breakpoint-size, $grid-breakpoint-anim);
    }
}
