.hero--standalone__item {
    overflow: hidden;
    position: relative;
    width: 100%;

    &__caption {
        background: rgba(black, 0.5);
        color: map-get($base__colors, "white");
        display: block;
        padding: 0 space--s(2);
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
    }
}

[data-theme="template-1"] {
    .header--hero {
        .hero .intro {
            margin-bottom: space(2);

            @include media(l-up) {
                padding-bottom: space(2);
            }

            @include media(m-to) {
                padding-bottom: space();
            }
        }
    }
    .header--hero-space {
        .hero .intro {
            margin-bottom: space(2);
        }
    }
    .hero {
        position: relative;

        .intro {
            padding-bottom: space();
        }

        .c-slider {
            @include media(m-to) {
                height: 350px;
            }

            &__show {
                background-color: var(--color-primary--normal);
                padding: space(1.38) space(0.38);
            }

            &__flag {
                background-color: rgba(map-get($base__colors, "white"), .4);

                &:checked {
                    background-color: var(--color-primary--normal);
                }
                &:not(:checked):focus,
                &:hover {
                    box-shadow: 0 0 0 1px rgba(map-get($base__colors, "black"), .2);
                }
            }

        }
        .c-slider__item-caption {
            text-transform: uppercase;

            @include media(m-to) {
                padding: 0 space(2);
            }
        }

        .search {
            @include box();
            @include typo--body(s);

            border: 2px solid rgba(white, .2);
            padding: templates("template-1", "hero-search-padding");
            transition: border-color 250ms;

            &:hover,
            &:focus {
                border-color: white;
            }
        }
    }
}

[data-theme="template-6"] {
    .header--hero {
        .hero {
            background-position: center;
            background-size: cover;
            height: calc(9 * 100vw / 16);
            max-height: 500px;
        }

        .h1 * {
            font-size: smaller;
        }
        .h3 * {
            font-size: larger;
        }
    }
}

[data-theme="template-2"],
[data-theme="template-5"] {
    .header--hero {
        .hero {
            text-align: center;

            &__content {
                @include media(m-to) {
                    padding-top: space();
                }
                
                @include media(l-up) {
                    @include o-content--s();
                    @include grid(column rowmid);
                    min-height: 50vh;
                }
            }
        }
    }
    .hero {
        background-position: center;
        background-size: cover;
        position: relative;

        &__content {
            position: relative;
        }

        .search {
            @include box();

            transition: border-color 250ms;

            &:hover,
            &:focus {
                border-color: white;
            }
        }
    }
}

[data-theme="template-2"] {
    .hero {
        @include space--t(padding-top padding-bottom);
    
        box-shadow: inset 0px 1px 5px 0 #000000;
        
        .search {
            border: 2px solid rgba(white, .2);
            padding: templates("template-2", "hero-search-padding");
        }
    }
}

[data-theme="template-3"] .hero {
    background-color: #eee;
    background-position: center;
    background-size: cover;
    position: relative;

    @include media(m-to) {
        @include space--x(padding-top);
        @include space--x(padding-bottom);
    }

    @include media(l-up) {
        @include space--h(padding-top);
        @include space--h(padding-bottom);
    }

    .search {
        @include box();

        background-color: map-get($base__colors, "white");
        border: 2px solid var(--color-primary--normal);
        padding: templates("template-3", "hero-search-padding");
        transition: border-color 250ms;
    }
}

[data-theme="template-4"] .hero {

    @include media(s) {
        &--standalone__item {
            max-height: 200px;
        }
    }
    @include media(m-to) {
        @include space--h(padding-bottom, $important: true);
    }
    @include media(l-up) {
        margin-bottom: -64px;

        &__text {
            @include space--h(padding-bottom, $addend: space--x(), $important: true);
            @include space--l($important: true);
        }
    }

    .search {
        @include box();

        border: 2px solid var(--color-secondary--normal);
        padding: templates("template-4", "hero-search-padding");
        transition: border-color 250ms;

        &:hover,
        &:focus {
            border-color: var(--color-secondary--focus);
        }
    }
}

[data-theme="template-5"] {
    .hero {
        .search {
            border: 2px solid rgba(white, .5);
            padding: templates("template-5", "hero-search-padding");
        }
    }
}

[data-theme="template-7"] {
    .header--hero {
        .header__navbar {
            position: absolute;
            width: 100%;
            z-index: 100;
        }

        .hero {
            overflow: hidden;
            background-position: center;
            background-size: cover;
            min-height: 100vh;
            max-height: none;
            height: auto;
            display: flex;
            align-items: flex-end;
            will-change: transform;
            
            &::after,
            &::before {
                content: '';
                position: absolute;
                width: 100%;
                z-index: 50;
            }

            &::after {
                bottom: 0;
                height: 75%;
                background-image: linear-gradient(to top, rgba(black, .85), ease-out, transparent);
            }
            &::before {
                top: 0;
                height: 25%;
                background-image: linear-gradient(to bottom, white, ease-out, transparent);
            }

            .button {
                background-color: var(--color-primary--normal);
            }
        }

        .hero__video {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            width: 100vw;
            height: 100vh;

            @media (min-aspect-ratio: 16 / 9) {
                height: calc(100vw * (9 / 16));
            }
            @media (max-aspect-ratio: 16 / 9) {
                width: calc(100vh / (9 / 16));
            }
        }

        .hero__content {
            position: relative;
            z-index: 100;
            padding-top: space(5);
            padding-bottom: space(3);
        }

        .hero__content__inner {
            max-width: 590px;
        }

        .hero__scroll-icon {
            position: absolute;
            z-index: 100;
            left: 50%;
            bottom: space();
            transform: translateX(-50%);
        }
    }
}


// Template overwrites
@each $_name, $_val in $templates {
    [data-theme="#{$_name}"] .hero {
        :link,
        :visited {
            &:not(.button) {
                border-bottom-color: templates($_name, "hero-links-border-color");
                color: templates($_name, "hero-links-color");
                font-weight: templates($_name, "hero-links-weight");

                &:after {
                    content: "➝";
                    margin-left: 5px;
                }

                &:hover,
                &:focus {
                    border-bottom-color: templates($_name, "hero-links-color");
                }
            }
        }
    }
}
