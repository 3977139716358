@each $_name, $_color in $base__colors {
    .u-txt--c-#{$_name} {
        color: $_color !important;
    }
}

@each $_name, $_color in $base__colors--overlay {
    .u-txt-overlay--c-#{$_name} {
        color: $_color !important;
    }
}

@each $_name, $_color in $base__colors--veillay {
    .u-txt-veillay--c-#{$_name} {
        color: $_color !important;
    }
}

@each $_name in $template__color-list {
    .u-txt--c-#{$_name} {
        color: var(--color-#{$_name}--normal) !important;
    }
}
