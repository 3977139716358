//**
//* Reset user agent"s default styles.
//*/

@mixin reset($elements: NULL) {
    
    @if $elements != NULL {
        
        @each $element in $elements {

            @if index(blockquote body button figure input keygen select textarea, $element) {
                margin: 0;
            }
            @if index(hr, $element) {
                border: none;
            }
            @if index(dl h1 h2 h3 h4 h5 h6 hr menu ol p pre ul, $element) {
                margin: {
                    top: 0;
                    bottom: 0;
                }
            }
            @if index(legend, $element) {
                padding: {
                    left: 0;
                    right: 0;
                }
            }
            @if index(dd, $element) {
                margin-left: 0;
            }
            @if index(menu ul ol, $element) {
                padding-left: 0;
            }
            @if index(ul ol, $element) {
                list-style-type: none;
            }
            @if index(address cite dfn em i var, $element) {
                font-style: normal;
            }
            @if index(b h1 h2 h3 h4 h5 h6 strong th, $element) {
                font-weight: normal;
            }
            @if index(table, $element) {
                border-spacing: 0;
            }
            @if index(button fieldset input keygen select td textarea th, $element) {
                padding: 0;
            }
            @if index(caption th, $element) {
                text-align: left;
            }
            @if index(small sub sup, $element) {
                font-size: inherit;
            }
            @if index(sub sup, $element) {
                vertical-align: baseline;
            }
            @if index(a del ins u s strike, $element) {
                text-decoration: none;
            }
            @if index(q, $element) {
                &:before,
                &:after {
                    content: none;
                }
            }
            @if index(a mark, $element) {
                cursor: text;
                color: inherit;
            }
            @if index(button fieldset input keygen textarea select, $element) {
                border: none;
            }
            @if index(button input keygen meter progress select textarea, $element) {
                appearance: none;
            }
            @if index(button input keygen select textarea, $element) {
                border-radius: 0;
            }
            @if index(button input keygen mark select textarea, $element) {
                background-color: transparent;
            }
            @if index(a button input select textarea, $element) {
                &:focus,
                &:active {
                    outline: none;
                }
            }
        }
    }
    @else {    
        margin: 0;
        padding: 0;
        border: none;
        border-radius: 0;
        border-spacing: 0;
        font-size: inherit;
        font-style: inherit;
        text-align: inherit;
        font-weight: inherit;
        list-style-type: none;
        text-decoration: none;
        vertical-align: baseline;
        background-color: transparent;
        
        &:after,
        &:before {
            content: none;
        }
        &:link,
        &:visited {
            cursor: text;
            color: inherit;
        }
        &:focus,
        &:active {
            outline: none;
        }
        
        appearance: none;
    }
}