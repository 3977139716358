.o-object-bg__media--video {
    height: auto;
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
}

.o-object-bg__media--cover {
    height: 100%;
    width: 100%;
}

@each $value in (
    // fill,
    // contain,
    cover,
    // none,
    // scale-down,
) {
    .o-object-bg__media--#{$value} {
        object-fit: $value;
    }
    @each $pv in (
        top,
        center,
        bottom,
    ) {
        @each $ph in (
            left,
            center,
            right,
        ) {
            .o-object-bg__media--#{$value}--#{str-slice($pv, 1, 1)}#{str-slice($ph, 1, 1)} {
                object-fit: $value;
                object-position: $pv $ph;
            }
        }
    }
}
