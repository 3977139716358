[data-theme="template-1"],
[data-theme="template-3"],
[data-theme="template-4"] {
    .block {
        border-radius: 0;
    }
}

[data-theme="template-2"],
[data-theme="template-5"] {
    .block {
        border-radius: 4px;
    }
    .block--top {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }
    .block--border-btm {
        border-bottom: 5px solid var(--color-primary--normal);
    }
}
