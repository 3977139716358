$o-content--max-width: 900px !default;
$o-content--max-width--s: 500px !default;

@mixin o-content {
    max-width: $o-content--max-width;
}
@mixin o-content--s {
    max-width: $o-content--max-width--s;
}

.o-content {
    @include o-content();
}
.o-content--s {
    @include o-content--s();
}
