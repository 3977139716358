.dash {
    @include reset(hr);
    display: inline-block;
    border-bottom: 1px solid black;
    width: space--l(2);
}
.dash--negative {
    border-bottom-color: map-get($base__colors, "white");
}

[data-theme="template-4"] {
    .dash {
        border-bottom: 2px solid var(--color-tertiary--normal);
    }
}
