.nav-link-container {
    position: relative;
}
.nav-link {
    border-bottom: 2px solid transparent;
    text-transform: uppercase;

    &,
    &:hover,
    &:focus {
        color: map-get($base__colors, "white");
    }
    &:hover,
    &:focus {
        border-bottom-color: map-get($base__colors, "white");
    }

    @include media(m-to) {
        font-size: small;
    }
}
.nav-link--button {
    background-color: transparent;
    display: inline-block;
    padding: space--s(2) space--s(2) space--s(2, -2);
    transition: {
        duration: 250ms;
        property: background-color, border-bottom-color;
    }

    &.is-active {
        background-color: map-get($base__colors--overlay, "iron");
    }
}

@each $_name, $_val in $templates {
    [data-theme="#{$_name}"] .nav-link {
        border-bottom-color: templates($_name, "nav-link-border-color");
        color: templates($_name, "nav-link-color");
        font-weight: templates($_name, "nav-links-weight");

        @include media(m-to) {
            color: templates($_name, "mobile-subnav-link-color");
        }

        &:hover,
        &:focus {
            background-color: templates($_name, "nav-link-bg--focus");
            border-bottom-color: templates($_name, "nav-link-color--focus");
            color: templates($_name, "nav-link-color--focus");

            @include media(m-to) {
                border-color: templates($_name, "mobile-subnav-link-color--focus");
                color: templates($_name, "mobile-subnav-link-color--focus");
            }
        }

        &__subnav-arrow {
            display: inline-block;
            margin-left: 10px;
            transition: {
                duration: 250ms;
                property: transform;
            }
        }

        &.is-active .nav-link__subnav-arrow {
            transform: rotate(180deg);
        }
    }

    [data-theme="#{$_name}"] .nav-link--button {
        padding: templates($_name, "nav-link-padding");

        @include media(m-to) {
            @include o-container();
            padding: templates($_name, "mobile-nav-link-padding");
        }

        &.is-active {
            background-color: templates($_name, "nav-active-bg-color");
        }
    }

    [data-theme="#{$_name}"] .nav-link__subnav {
        @include box();
        background-color: templates($_name, "subnav-bg-color");
        box-shadow: 0 0 25px -4px rgba(black, .41);
        position: absolute;
        text-align: right;
        min-width: 120%;
        right: 0;
        top: 100%;
        z-index: 9999;

        @include media(m-to) {
            background-color: templates($_name, "mobile-subnav-bg-color");
            position: relative;
            top: 0;
            text-align: left;
            max-width: 100%;
        }

        &__list {
            padding: templates($_name, "subnav-padding");

            @include media(m-to) {
                padding: space();
            }
        }

        &__link {
            display: inline-block;
            &,
            &:link,
            &:visited {
                border-bottom-color: templates($_name, "subnav-link-border-color");
                color: templates($_name, "subnav-link-color");

                &:hover,
                &:focus {
                    border-bottom-color: templates($_name, "subnav-link-color--focus");
                }
            }
        }
    }

    * + * > .nav-link__subnav__link {
        margin-top: space--s();
    }
}
