// template dynamic colors
$template__color-list: "primary" "secondary" "tertiary" !default;

$template-1: (
    // global
    "background-color": map-get($base__colors, "lily-white"),
    "headings-weight": bold,
    "buttons-weight": normal,
    "links-weight": normal,
    "headings-color": var(--color-primary--normal),
    "links-color": var(--color-secondary--normal),
    "links-border-color": var(--color-secondary--normal),
    "links-color--focus": var(--color-primary--normal),
    "body-color": map-get($base__colors, "dark-gray"),
    "padding": 35px,

    // breakcrums
    "breadcrumb-links-color": var(--color-secondary--normal),
    "breadcrumb-links-border-color": var(--color-secondary--normal),
    "breadcrumb-links-color---focus": var(--color-secondary--focus),
    
    // header
    "header-color": map-get($base__colors, "white"),

    // hero
    "hero-links-weight": normal,
    "hero-links-color": map-get($base__colors, "white"),
    "hero-links-border-color": rgba(map-get($base__colors, "white"), .2),
    "hero-search-bg": transparent,
    "hero-search-padding": 2px,

    // navigation
    "hamburger-bg": rgba(map-get($base__colors, "white"), .2),
    "hamburger--focus-bg": map-get($base__colors, "white"),
    "hamburger-border-color": transparent,
    "hamburger-text-color": map-get($base__colors, "white"),
    "hamburger--focus-text-color": var(--color-primary--normal),
    "nav-bg": var(--color-primary--normal),
    "nav-links-weight": normal,
    "nav-active-bg-color": rgba(black, .1),
    "nav-link-color": map-get($base__colors, "white"),
    "nav-link-border-color": transparent,
    "nav-link-color--focus": map-get($base__colors, "white"),
    "nav-link-bg--focus": transparent,
    "nav-link-padding": 20px,

    "subnav-padding": 10px,
    "subnav-bg-color": var(--color-primary--normal),
    "subnav-link-color": map-get($base__colors, "white"),
    "subnav-link-color--focus": map-get($base__colors, "white"),
    "subnav-link-border-color": rgba(map-get($base__colors, "white"), .2),

    "mobile-nav-link-padding": 15px 20px,
    "mobile-subnav-bg-color": var(--color-primary--focus),
    "mobile-subnav-link-color": map-get($base__colors, "white"),
    "mobile-subnav-link-color--focus": map-get($base__colors, "white"),

    // footer
    "footer-bg-color": var(--color-primary--normal)
);

$template-2: (
    // global
    "background-color": map-get($base__colors, "white"),
    "headings-weight": bold,
    "buttons-weight": bold,
    "links-weight": normal,
    "headings-color": var(--color-secondary--normal),
    "links-color": var(--color-primary--normal),
    "links-border-color": var(--color-primary--normal),
    "links-color--focus": var(--color-primary--focus),
    "body-color": map-get($base__colors, "black"),
    "padding": 20px,

    // hero
    "hero-links-weight": normal,
    "hero-links-color": map-get($base__colors, "white"),
    "hero-links-border-color": rgba(map-get($base__colors, "white"), .2),
    "hero-search-bg": transparent,
    "hero-search-padding": 2px,

    // navigation
    "hamburger-bg": var(--color-secondary--normal),
    "hamburger--focus-bg": var(--color-secondary--normal),
    "hamburger-border-color": transparent,
    "hamburger-text-color": map-get($base__colors, "white"),
    "hamburger--focus-text-color": map-get($base__colors, "white"),
    "nav-bg": var(--color-secondary--normal),
    "nav-links-weight": bold,
    "nav-active-bg-color": rgba(black, .1),
    "nav-link-color": var(--color-secondary--normal),
    "nav-link-border-color": transparent,
    "nav-link-color--focus": var(--color-primary--normal),
    "nav-link-bg--focus": transparent,
    "nav-link-padding": 20px,

    "subnav-padding": 10px,
    "subnav-bg-color": var(--color-primary--normal),
    "subnav-link-color": map-get($base__colors, "white"),
    "subnav-link-color--focus": map-get($base__colors, "white"),
    "subnav-link-border-color": rgba(map-get($base__colors, "white"), .2),

    "mobile-nav-link-padding": 15px 20px,
    "mobile-subnav-bg-color": var(--color-secondary--focus),
    "mobile-subnav-link-color": map-get($base__colors, "white"),
    "mobile-subnav-link-color--focus": map-get($base__colors, "white"),

    // footer
    "footer-bg-color": var(--color-secondary--normal)
);

$template-3: (
    "background-color": map-get($base__colors, "white"),
    "headings-weight": bold,
    "buttons-weight": bold,
    "links-weight": bold,
    "headings-color": var(--color-primary--normal),
    "links-color": var(--color-secondary--normal),
    "links-border-color": var(--color-secondary--normal),
    "links-color--focus": var(--color-secondary--focus),
    "body-color": map-get($base__colors, "black"),
    "padding": 10px,

    // hero
    "hero-links-weight": normal,
    "hero-links-color": var(--color-primary--normal),
    "hero-links-border-color": rgba(map-get($base__colors, "black"), .2),
    "hero-search-bg": transparent,
    "hero-search-padding": 2px,

    // navigation
    "hamburger-bg": transparent,
    "hamburger--focus-bg": transparent,
    "hamburger-border-color": var(--color-primary--normal),
    "hamburger-text-color": var(--color-primary--normal),
    "hamburger--focus-text-color": var(--color-primary--normal),
    "nav-bg": map-get($base__colors, "white"),
    "nav-links-weight": bold,
    "nav-active-bg-color": rgba(black, .1),
    "nav-link-color": var(--color-primary--normal),
    "nav-link-border-color": transparent,
    "nav-link-color--focus": var(--color-primary--focus),
    "nav-link-bg--focus": var(--color-primary--transparent),
    "nav-link-padding": 5px 10px,

    "subnav-padding": 10px,
    "subnav-bg-color": var(--color-primary--normal),
    "subnav-link-color": map-get($base__colors, "white"),
    "subnav-link-color--focus": map-get($base__colors, "white"),
    "subnav-link-border-color": rgba(map-get($base__colors, "white"), .2),

    // footer
    "footer-bg-color": var(--color-tertiary--normal)
);

$template-4: (
    // global
    "background-color": map-get($base__colors, "lily-white"),
    "headings-weight": bold,
    "buttons-weight": bold,
    "links-weight": bold,
    "headings-color": var(--color-primary--normal),
    "links-color": var(--color-secondary--normal),
    "links-color--focus": var(--color-secondary--focus),
    "body-color": map-get($base__colors, "black"),
    "padding": 35px,

    // hero
    "hero-links-weight": normal,
    "hero-links-color": map-get($base__colors, "black"),
    "hero-search-bg": map-get($base__colors--veillay, "black"),
    "hero-links-border-color": rgba(map-get($base__colors, "black"), .2),
    "hero-search-padding": 2px,

    // navigation
    "hamburger-bg": var(--color-primary--normal),
    "hamburger--focus-bg": var(--color-tertiary--normal),
    "hamburger-border-color": transparent,
    "hamburger-text-color": map-get($base__colors, "white"),
    "hamburger--focus-text-color": var(--color-primary--normal),
    "nav-bg": var(--color-tertiary--normal),
    "nav-links-weight": bold,
    "nav-active-bg-color": rgba(black, .1),
    "nav-link-color": var(--color-primary--normal),
    "nav-link-color--focus": var(--color-primary--focus),
    "nav-link-bg--focus": transparent,
    "nav-link-padding": 20px 10px,

    "subnav-padding": 10px,
    "subnav-bg-color": var(--color-secondary--normal),
    "subnav-link-color": map-get($base__colors, "white"),
    "subnav-link-color--focus": map-get($base__colors, "white"),
    "subnav-link-border-color": rgba(map-get($base__colors, "white"), .2),

    "mobile-nav-link-padding": 10px 15px,
    "mobile-subnav-bg-color": var(--color-secondary--focus),

    // footer
    "footer-bg-color": var(--color-primary--focus)
);

$template-5: (
    // global
    "background-color": map-get($base__colors, "white"),
    "headings-weight": normal,
    "buttons-weight": bold,
    "links-weight": normal,
    "headings-color": var(--color-secondary--normal),
    "links-color": var(--color-primary--normal),
    "links-border-color": var(--color-primary--normal),
    "links-color--focus": var(--color-primary--focus),
    "body-color": map-get($base__colors, "dark-gray"),
    "padding": 30px,

    // hero
    "hero-links-weight": normal,
    "hero-links-color": map-get($base__colors, "white"),
    "hero-links-border-color": rgba(map-get($base__colors, "white"), .2),
    "hero-search-bg": transparent,
    "hero-search-padding": 2px,

    // navigation
    "hamburger-bg": hsla(0, 0%, 100%, .1),
    "hamburger--focus-bg": map-get($base__colors, "white"),
    "hamburger-border-color": transparent,
    "hamburger-text-color": map-get($base__colors, "white"),
    "hamburger--focus-text-color": map-get($base__colors, "white"),
    "nav-bg": var(--color-secondary--normal),
    "nav-links-weight": bold,
    "nav-active-bg-color": rgba(black, .1),
    "nav-link-color": map-get($base__colors, "white"),
    "nav-link-border-color": transparent,
    "nav-link-color--focus": map-get($base__colors, "white"),
    "nav-link-bg--focus": transparent,
    "nav-link-padding": 20px,

    "subnav-padding": 10px,
    "subnav-bg-color": var(--color-primary--normal),
    "subnav-link-color": map-get($base__colors, "white"),
    "subnav-link-color--focus": map-get($base__colors, "white"),
    "subnav-link-border-color": rgba(map-get($base__colors, "white"), .2),

    "mobile-nav-link-padding": 15px 20px,
    "mobile-subnav-bg-color": var(--color-secondary--focus),
    "mobile-subnav-link-color": map-get($base__colors, "white"),
    "mobile-subnav-link-color--focus": map-get($base__colors, "white"),

    // footer
    "footer-bg-color": var(--color-secondary--normal)
);

$template-6: (
    // global
    "background-color": map-get($base__colors, "lily-white"),
    "headings-weight": bold,
    "buttons-weight": bold,
    "links-weight": normal,
    "headings-color": var(--color-secondary--normal),
    "links-color": var(--color-primary--normal),
    "links-border-color": var(--color-primary--normal),
    "links-color--focus": var(--color-primary--focus),
    "body-color": map-get($base__colors, "black"),
    "padding": 20px,

    // hero
    "hero-links-weight": normal,
    "hero-links-color": map-get($base__colors, "white"),
    "hero-links-border-color": rgba(map-get($base__colors, "white"), .2),
    "hero-search-bg": transparent,
    "hero-search-padding": 2px,

    // navigation
    "hamburger-bg": var(--color-secondary--normal),
    "hamburger--focus-bg": var(--color-secondary--normal),
    "hamburger-border-color": transparent,
    "hamburger-text-color": map-get($base__colors, "white"),
    "hamburger--focus-text-color": map-get($base__colors, "white"),
    "nav-bg": var(--color-secondary--normal),
    "nav-links-weight": bold,
    "nav-active-bg-color": rgba(black, .1),
    "nav-link-color": var(--color-secondary--normal),
    "nav-link-border-color": transparent,
    "nav-link-color--focus": var(--color-primary--normal),
    "nav-link-bg--focus": transparent,
    "nav-link-padding": 20px,

    "subnav-padding": 10px,
    "subnav-bg-color": var(--color-primary--normal),
    "subnav-link-color": map-get($base__colors, "white"),
    "subnav-link-color--focus": map-get($base__colors, "white"),
    "subnav-link-border-color": rgba(map-get($base__colors, "white"), .2),

    "mobile-nav-link-padding": 15px 20px,
    "mobile-subnav-bg-color": var(--color-secondary--focus),
    "mobile-subnav-link-color": map-get($base__colors, "white"),
    "mobile-subnav-link-color--focus": map-get($base__colors, "white"),

    // footer
    "footer-bg-color": var(--color-secondary--normal)
);

$template-7: (
    // global
    "background-color": map-get($base__colors, "lily-white"),
    "headings-weight": bold,
    "buttons-weight": bold,
    "links-weight": normal,
    "headings-color": var(--color-secondary--normal),
    "links-color": var(--color-primary--normal),
    "links-border-color": var(--color-primary--normal),
    "links-color--focus": var(--color-primary--focus),
    "body-color": map-get($base__colors, "black"),
    "padding": 20px,

    // hero
    "hero-links-weight": normal,
    "hero-links-color": map-get($base__colors, "white"),
    "hero-links-border-color": rgba(map-get($base__colors, "white"), .2),
    "hero-search-bg": transparent,
    "hero-search-padding": 2px,

    // navigation
    "hamburger-bg": var(--color-secondary--normal),
    "hamburger--focus-bg": var(--color-secondary--normal),
    "hamburger-border-color": transparent,
    "hamburger-text-color": map-get($base__colors, "white"),
    "hamburger--focus-text-color": map-get($base__colors, "white"),
    "nav-bg": var(--color-secondary--normal),
    "nav-links-weight": bold,
    "nav-active-bg-color": rgba(black, .1),
    "nav-link-color": var(--color-secondary--normal),
    "nav-link-border-color": transparent,
    "nav-link-color--focus": var(--color-primary--normal),
    "nav-link-bg--focus": transparent,
    "nav-link-padding": 20px,

    "subnav-padding": 10px,
    "subnav-bg-color": var(--color-primary--normal),
    "subnav-link-color": map-get($base__colors, "white"),
    "subnav-link-color--focus": map-get($base__colors, "white"),
    "subnav-link-border-color": rgba(map-get($base__colors, "white"), .2),

    "mobile-nav-link-padding": 15px 20px,
    "mobile-subnav-bg-color": var(--color-secondary--focus),
    "mobile-subnav-link-color": map-get($base__colors, "white"),
    "mobile-subnav-link-color--focus": map-get($base__colors, "white"),

    // footer
    "footer-bg-color": var(--color-secondary--normal)
);


$templates: (
    "template-1": $template-1,
    "template-2": $template-2,
    "template-3": $template-3,
    "template-4": $template-4,
    "template-5": $template-5,
    "template-6": $template-6,
    "template-7": $template-7
);

// Example:
//
// body {
//     color: templates("template-1", "background-color");
// }
@function templates($template, $property) {
    @return map-get(map-get($templates, $template), $property);
}
