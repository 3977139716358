.hamburger {
    &:is(button) {
        border: 2px solid black;
        padding: space(3/4);
        width: auto;
    }

    &__line {
        background-color: rgba(map-get($base__colors, "black"), .7);
        height: 2px;
        margin: space(1/12) 0;
    }

    &.is-active {
        background-color: map-get($base__colors, "black");
    }
}

@each $_name, $_val in $templates {
    [data-theme="#{$_name}"] .hamburger {
        background-color: templates($_name, "hamburger-bg");
        border-color: templates($_name, "hamburger-border-color");

        &__line {
            background-color: templates($_name, "hamburger-text-color");
        }

        &.is-active {
            background-color: templates($_name, "hamburger-focus-bg");

            .hamburger__line {
                background-color: templates($_name, "hamburger-focus-text-color");
            }
        }
    }
}
