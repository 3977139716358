.header {
    :any-link:not([class]) {
        color: inherit;
    }
}

@each $_name, $_val in $templates {
    [data-theme="#{$_name}"] {
        @if templates($_name, "header-color") != null {
            .header {
                color: templates($_name, "header-color");

                :any-link:not([class]) {
                    color: templates($_name, "header-color");
                    border-bottom-color: rgba(templates($_name, "header-color"), .2);
                    font-weight: bold;

                    &:hover,
                    &:focus {
                        border-bottom-color: templates($_name, "header-color");
                        color: templates($_name, "header-color");
                    }
                }
            }
        }
    }
}

.header__divider {
    align-items: flex-start;
}

[data-theme="template-1"] {
    .header {
        @include box();
        background-color: var(--color-primary--normal);
        position: relative;

        &:before {
            background-image: linear-gradient(135deg, rgba(0, 180, 234, .5), rgba(white, 0));
            border-bottom: 40px solid rgba(black, .3);
            content: "";
            display: block;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
        }

        &--hero {
            &:before {
                border-bottom: 70px solid rgba(black, .3);
            }
        }
    }
}

[data-theme="template-2"],
[data-theme="template-5"] {
    .header {
        .hero {
            @include box();
            position: relative;

            &:before {
                content: "";
                display: block;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
            }
        }
    }
}

[data-theme="template-2"],
[data-theme="template-6"],
[data-theme="template-7"] {
    .header {
        color: var(--color-secondary--normal);
        
        .hero {
            &:before {
                background-image: linear-gradient(315deg, var(--color-secondary--normal), var(--color-primary--transparent));
            }
        }
    }
}

[data-theme="template-3"] {
    .header {
        .logo {
            width: 240px;
        }

        .header__item {
            box-shadow: 0 0 30px 0 rgba(0, 0, 0, .2);
            padding-bottom: 30px;
            position: relative;
            z-index: 2;
        }

        &--hero .hero {
            @include box();
            position: relative;

            &:before {
                background-image: linear-gradient(315deg, hsla(0, 0%, 100%, .81), hsla(0, 0%, 100%, .81));
                content: "";
                display: block;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
            }
        }
    }
}

[data-theme="template-4"] {
    .header {
        @include box();
        background-color: white;

        &__divider {
            @include space--m(padding-bottom);
            border-bottom: 5px solid var(--color-tertiary--normal);
        }
    }
}


[data-theme="template-5"] {
    .header {
        background-color: var(--color-secondary--normal);
    }
    .header--hero {
        background-color: var(--color-tertiary--normal);
        
        &,
        .h1 {
            @include font-smoothing(false, false);
            color: map-get($base__colors, "white");
        }
        
        .hero {
            &:before {
                background-image: linear-gradient(to bottom, rgba(black, .5), transparent);
            }
        }
    }
    .searchbox {
        &,
        .h1 {
            @include font-smoothing(false, false);
            color: map-get($base__colors, "white");
        }
        
        background-color: var(--color-secondary--normal);
        
        .button {
            background-color: var(--color-primary--normal);
            color: map-get($base__colors, 'white');
        }
    }
}
