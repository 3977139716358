$s-slider__max-items: 50;

.c-slider__list {
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
}

.c-slider__show {
  background-color: black;
  color: white;
  display: inline-block;
  padding: 1em;
  transition: opacity 350ms;

  &:focus,
  &:hover {
    opacity: .8;
  }
  &:hover {
    cursor: pointer;
  }
}

.c-slider__hide {
  appearance: none;
  background-clip: content-box;
  background-color: transparent;
  border-radius: 50%;
  margin: 0;

  &:checked {
    background-image:
    repeating-linear-gradient(
      45deg,
      transparent,
      transparent calc(50% - 2px),
      white calc(50% - 2px),
      white calc(50% + 2px)
    ),
    repeating-linear-gradient(
      -45deg,
      transparent,
      transparent calc(50% - 2px),
      white calc(50% - 2px),
      white calc(50% + 2px)
    );
  }
  &:hover {
    cursor: pointer;
  }
}

.c-slider__flag {
  appearance: none;
  background-clip: content-box;
  background-color: white;
  border: 2px solid transparent;
  border-radius: 50%;
  margin: 0;
  transition-duration: 350ms;
  transition-property: background-color, box-shadow;

  &:checked {
    background-color: black;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
  &:not(:checked):focus,
  &:hover {
    box-shadow: 0 0 0 1px rgba(black, .4);
  }
}



$s-slider__flag--padding: 20px;
$s-slider__flag--size: 20px;
$s-slider__flag--hspace: 5px;
$s-slider__full--padding: 20px;

.c-slider {
  overflow: hidden;
  position: relative;
}

.c-slider__hide {
  height: $s-slider__flag--size;
  width: $s-slider__flag--size;
}

.c-slider__flag {
  height: $s-slider__flag--size;
  width: $s-slider__flag--size;
}

.c-slider__flag,
.c-slider__show {
  position: absolute;
  z-index: 1;
}

.c-slider__list {
  display: flex;
  transition: transform 350ms;
  will-change: transform;
  @for $i from 1 to $s-slider__max-items {
    .c-slider__flag:nth-of-type(#{$i}):checked ~ & {
      transform: translateX(-#{percentage($i - 1)});
    }
  }
}

.c-slider__item {
  flex: 0 0 100%;

  > :hover {
    cursor: pointer;
  }
}

.c-slider__show {
  top: 50%;
  transform: translateY(-50%);

  .c-slider__flag:not(:checked) + &,
  .c-slider__flag:not(:checked) + & + & {
    display: none;
  }
}
.c-slider__show--prev {
  left: 0;
}
.c-slider__show--next {
  right: 0;
}

.c-slider__hide {
  position: fixed;
  top: $s-slider__full--padding;
  right: $s-slider__full--padding;
  z-index: 9999;

  &:not(:checked) {
    display: none;
  }
}

.c-slider__full {
  background-color: black;
  bottom: 0;
  display: flex;
  left: 0;
  padding: $s-slider__full--padding;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 350ms;
  z-index: 999;

  img {
    height: auto;
    margin: auto;
    max-height: 100%;
    max-width: 100%;
    width: auto;
  }

  .c-slider__hide:not(:checked) + & {
    display: none;
    opacity: 0;
    pointer-events: none;
  }
}

.c-slider__flag {
  .c-slider--flags--tl &,
  .c-slider--flags--tr & {
    top: $s-slider__flag--padding;
  }
  .c-slider--flags--bl &,
  .c-slider--flags--br & {
    bottom: $s-slider__flag--padding;
  }
  .c-slider--flags--bl &,
  .c-slider--flags--tl & {
    left: $s-slider__flag--padding - $s-slider__flag--hspace;
  }
  .c-slider--flags--br &,
  .c-slider--flags--tr & {
    right: $s-slider__flag--padding - $s-slider__flag--hspace;
  }
  @for $i from 1 to $s-slider__max-items {
    &:nth-of-type(#{$i}) {
      .c-slider--flags--bl &,
      .c-slider--flags--tl & {
        transform: translateX(#{percentage($i - 1)}) translateX(#{$i * $s-slider__flag--hspace});
      }
    }
    &:nth-last-of-type(#{$i}) {
      .c-slider--flags--br &,
      .c-slider--flags--tr & {
        transform: translateX(-#{percentage($i - 1)}) translateX(-#{$i * $s-slider__flag--hspace});
      }
    }
  }
}

.c-slider,
.c-slider__list {
    height: 100%;
}





// for the new gallery from titan

.slider__prev,
.slider__next,
.slider__peeps,
.slider__progress {
    position: absolute;
    z-index: 1;
}
.slider__prev,
.slider__next {
    top: 50%;
    transform: translateY(-50%);
}
.slider__prev {
    left: 0;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
}
.slider__next {
    right: 0;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
}
.slider__peeps {
    top: space--s();
    left: 50%;
    transform: translateX(-50%);
}
.slider__peep,
.slider__prev,
.slider__next {
    cursor: pointer;
    padding: space--s();

    &:focus {
        outline: none;
    }
}
.slider__peep {
    border-radius: 50%;

    &:focus {
        opacity: .7;
    }
    &.is-active {
        background-color: map-get($base__colors--veillay, 'iron');
    }
}
.slider__progress {
    font-weight: bold;
    padding: {
        right: space--s();
        left: space--s();
    }
}
