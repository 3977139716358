.jumpoffs--hero__first {
    @include media(l-up) {
        min-height: 400px;
    }
}

.jumpoffs {
	&--featured {
		&__item { display: block; }
	}
}
